import React from 'react';
import { useTranslation } from 'react-i18next';

import intercom from './intercom.jpg';
import {
  ButtonPrimary,
  ButtonWhite,
  CustomerServiceWrapper,
  InfoGroup,
} from './styles';

const CustomerService = () => {
  const { t } = useTranslation();
  return (
    <CustomerServiceWrapper>
      <div className="hereForYouDesktop">
        <div className="grid">
          <div>
            <h2>{t("CustomerService..We're here for you")}</h2>
            <div className="secondaryGridA">
              <div
                className="personalAssistant"
                onClick={() =>
                  typeof window !== 'undefined' && window.Intercom('show')
                }
              >
                <img src={intercom} alt="/" />
                <p>
                  {t('CustomerService..Chat with a personal travel assistant')}
                </p>
              </div>
              <div
                className="emailUs"
                onClick={() =>
                  typeof window !== 'undefined' && window.Intercom('show')
                }
              >
                <p>{t('CustomerService..Chat with us')}</p>
                <p>
                  <u>
                    {t(
                      'CustomerService..Personal travel experts ready to help'
                    )}
                  </u>
                </p>
              </div>
              <div className="available">
                {/* <p>{t("CustomerService..We're available")}</p> */}
                <p>{t('CustomerService..Monday to Sunday 9am-6pm')}</p>
              </div>
            </div>
          </div>
          <div>
            <h2>{t('CustomerService..Top 5 FAQs')}</h2>
            <div className="secondaryGridB">
              <p>
                <a href="/help">
                  {t('CustomerService..Do you support one way flights?')}
                </a>
              </p>
              <p>
                <a href="/help">{t('CustomerService..How does it work?')}</a>
              </p>
              <p>
                <a href="/help">
                  {t(
                    'CustomerService..My passport expires soon, how can I book?'
                  )}
                </a>
              </p>
              <p>
                <a href="/help">
                  {t('CustomerService..When will I get my flight ticket?')}
                </a>
              </p>

              <p>
                <a href="/help">
                  {t(
                    'CustomerService..Is it possible to pay instalments on flights?'
                  )}
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="hereForYouMobile">
        <InfoGroup>
          <p>{t('CustomerService..Chat with us')}</p>
          <p>{t('CustomerService..Personal travel experts ready to help')}</p>
        </InfoGroup>
        <InfoGroup>
          <p>We're available</p>
          <p>Monday to Sunday 10am-5pm</p>
        </InfoGroup>
      </div>

      <div className="travelCtas">
        <hr />
        <h3>{t('CustomerService..Travel the smart way')}</h3>
        <div className="travelSmartButtonWrapper">
          <ButtonPrimary
            className="desktopOnly"
            onClick={() => {
              window.window.scroll({ top: 0, left: 0, behavior: 'smooth' });
            }}
            alt="get started"
          >
            {t('CustomerService..Get started')}
          </ButtonPrimary>

          <ButtonPrimary
            className="mobileOnly"
            onClick={() => {
              window.window.scroll({ top: 0, left: 0, behavior: 'smooth' });
            }}
            alt="get started"
          >
            {t('CustomerService..Get started')}
          </ButtonPrimary>

          <ButtonWhite href="/about-us" alt="discover why">
            {t('CustomerService..Discover why')}
          </ButtonWhite>
        </div>
      </div>
    </CustomerServiceWrapper>
  );
};

export default CustomerService;
