// eslint-disable
// tslint:disable

import React, { useContext } from 'react';
import styled from 'styled-components';
import { FlightTicketFunction } from '../../MappedFlightTickets/MappedFlightTickets';
// import { MobileOnly, DesktopOnly } from 'routes/LandingPage/components';
// import { ViewDetailsToggleButton } from 'components/ViewDetailsToggleButton';
import AppContext from 'Context/AppContext';
// import { FORMSTEPS } from 'Utils';
// import { BOOKING_TYPES } from '../../constants/constants';
import { darkBlue2 } from '../../styles/colors';

const Title = styled.div`
  color: ${darkBlue2};
  margin-bottom: 9px;
  font-family: 'Public Sans';
  font-style: normal;
  text-align: left;
  font-weight: 700;
  letter-spacing: 0px;
`;
const TitleDesktop = styled(Title)`
  @media (max-width: 999px) {
    display: none;
  }
  font-size: 24px;
  line-height: 28px;
`;
const TitleMobile = styled(Title)`
  @media (min-width: 1000px) {
    display: none;
  }
  font-size: 20px;
  line-height: 24px;
  margin-left: 18px;
`;

const FlightTicket = () => {
  const context = useContext(AppContext);
  const { currentFormStepContext, selectedFlightTicket, typeBookingContext } =
    context;

  const checkout = true;

  return (
    // <FlightTicketWrapper isOneWay={selectedFlightTicket.return.length === 0}>
    <>
      <TitleDesktop>Your flight</TitleDesktop>
      <TitleMobile>Your flight details</TitleMobile>

      {FlightTicketFunction(selectedFlightTicket, 10, 1, checkout)}
    </>
    // </FlightTicketWrapper>
  );
};

export default FlightTicket;

// const FlightTicketWrapper = styled.div<{
//   isOneWay: boolean;
// }>`
//   order: 1;
//   margin-bottom: 24px;
//   @media (max-width: 999px) {
//     order: 2;
//     width: 100%;
//     margin-bottom: 34px;
//   }

//   h1 {
//     font-size: 18px;
//     font-weight: 300;
//   }

//   hr,
//   .viewDetailsWrapper,
//   .priceDisplay,
//   .priceTag,
//   .selectFlightTicket {
//     display: none !important;
//   }

//   .showDetailsDesktop {
//     display: inline-block !important;
//     padding-bottom: 3px;
//   }

//   @media (min-width: 1000px) {
//     .onlyCheckout {
//       display: inline-block;
//     }
//     text-align: left;

//     h1 {
//       font-size: 38px;
//     }

//     /*
//   This code is used to modify the Flight Ticket on the Desktop view, on the checkout page.
//   There needs to be a small adjustment depending on wether the ticket is oneway or not one way.
//   */
//     .searchResultCheckoutGrid {
//       .departureTime,
//       .arrivalTime {
//         font-weight: 500 !important;
//       }
//       .onlyCheckout {
//         display: inline;
//       }
//       .travelDisplayHolder {
//         padding: 0;
//       }
//       > div {
//         grid-template-columns: ${(props: any) =>
//           props.isOneWay ? '1fr' : '1fr 1fr'} !important;
//         max-width: 100%;
//       }
//     }

//     .bottomRowWrapperDisplay {
//       display: none !important;
//     }

//     .detailsPaymentPlanDisplay {
//       display: none !important;
//     }
//   }

//   .viewDetailsToggle {
//     text-align: center;
//     font-weight: 500;
//     color: black;
//     margin-bottom: 15px;
//     margin-top: 10px;
//   }

//   .viewDetailsIcon {
//     margin-left: 10px;
//     padding: 4px;
//     width: 20px;
//     height: 20px;
//     background-color: #f0f0f0;
//     display: inline-block;
//     cursor: pointer;
//     border: 1px #80808091 solid;
//     border-radius: 50%;
//     vertical-align: middle;
//   }

//   @media (max-width: 1000px) {
//     /* padding-left: 12px;
//     padding-right: 12px; */
//     border-radius: none;
//     padding-bottom: 0;

//     .totalFlightDuration {
//       display: inline-block !important;
//     }
//     h2 {
//       display: none;
//     }
//     .bottomRowWrapperDisplay {
//       display: flex;
//       justify-content: center;
//     }
//     .searchResultCheckoutGrid {
//       p,
//       span,
//       b {
//         text-align: left;
//       }
//       .travelDisplayHolder {
//         padding-left: 0;
//         padding-right: 0;
//       }
//       .viewDetailsMobileButton {
//         width: 100% !important;
//         grid-column: 2/3;
//         text-align: center !important;
//         width: 30px;
//         height: 30px;
//         background-color: pink;
//       }
//     }
//   }

//   .priceUnit {
//     display: none;
//   }
// `;
