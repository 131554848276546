/* eslint-disable react/prop-types */
import React, { useContext } from 'react';
import { withRouter } from 'react-router-dom';
import { dayjs } from 'Services/dayjs';
import pages from 'pages';
import { topAirports, topDestinations } from './utils/constants';
import { KIND_OF_TRIP, CABIN_CLASS } from '../../constants/constants';
import { isMobileDevice } from '../../Utils';
import { SearchFormWrapper } from './styles/styles';
import AppContext from 'Context/AppContext';
import { useForm, FormProvider } from 'react-hook-form';
import SearchLocationPicker from './components/SearchLocationPicker';
import SearchDatePicker from './components/SearchDatePicker';
import SearchButton from './components/SearchButton';
import CabinAndPassengersPickers from './components/CabinAndPassengersPickers';
import { useFlightsContext } from 'Context/FlightsProvider';
import { useCurrencyContext } from 'Context/CurrencyProvider';

const SearchForm = withRouter((props) => {
  const context = useContext(AppContext);
  const flightsContext = useFlightsContext();
  const { currency } = useCurrencyContext();
  const { lastSearch } = context;

  const methods = useForm({
    defaultValues: {
      flyingFrom: lastSearch.flyingFrom || topAirports[1].key,
      flyingTo: lastSearch.flyingTo || topDestinations[0].key,
      dateFrom: lastSearch.dateFrom || dayjs().add(60, 'day'),
      dateTo: lastSearch.dateTo || dayjs().add(74, 'day'),
      noAdults: lastSearch.noAdults || 1,
      noChildren: lastSearch.noChildren || 0,
      noInfants: lastSearch.noInfants || 0,
      typeFlight: lastSearch.typeFlight || KIND_OF_TRIP.ROUND,
      cabin: lastSearch.cabin || CABIN_CLASS.ECONOMY,
    },
  });

  // methods.setValue('flyingFrom', lastSearch.flyingFrom || topAirports[1].key);
  // methods.setValue('flyingTo', lastSearch.flyingTo || topDestinations[0].key);

  const handleNewSearch = (formValues) => {
    const { lastSearch, setLastSearch } = context;

    const {
      flyingFrom,
      flyingTo,
      dateFrom,
      dateTo,
      noAdults,
      noChildren,
      noInfants,
      cabin,
    } = formValues;
    let tempValues = isMobileDevice()
      ? {
          ...formValues,
          ...lastSearch,
        }
      : {
          ...lastSearch,
          ...formValues,
        };

    let defaults = {
      ...tempValues,
      flyingFrom: flyingFrom || topAirports[1].key,
      flyingTo: flyingTo || topDestinations[0].key,
      dateFrom: dateFrom || dayjs().add(60, 'day'),
      noAdults: noAdults || 1,
      noChildren: noChildren || 0,
      noInfants: noInfants || 0,
      cabin: cabin || CABIN_CLASS.ECONOMY,
      dateTo:
        lastSearch.typeFlight === KIND_OF_TRIP.ONE_WAY
          ? null
          : dateTo || dayjs().add(74, 'day'),
      typeFlight: lastSearch.typeFlight,
    };
    setLastSearch(formValues);
    redirectToSearchResult(defaults);
  };

  const redirectToSearchResult = ({
    dateFrom,
    dateTo,
    flyingFrom,
    flyingTo,
    noAdults,
    noChildren,
    noInfants,
    cabin,
  }) => {
    const urlDateFormat = 'YYYYMMDD';
    let resultUrl;

    return;

    if (dateTo) {
      resultUrl = `/round/${flyingTo}/${flyingFrom}/${dateFrom.format(
        urlDateFormat
      )}/${dateTo.format(
        urlDateFormat
      )}/${noAdults}/${noChildren}/${noInfants}/${cabin}/${currency}`;
    } else {
      resultUrl = `/oneway/${flyingTo}/${flyingFrom}/${dateFrom.format(
        urlDateFormat
      )}/${dateFrom.format(
        urlDateFormat
      )}/${noAdults}/${noChildren}/${noInfants}/${cabin}/${currency}`;
    }

    props.history.push({
      pathname: pages.querySearchPage + resultUrl,
    });
  };

  const isLoading =
    flightsContext.isPriceLoading ||
    flightsContext.isQualityLoading ||
    flightsContext.isDurationLoading;

  return (
    <FormProvider {...methods}>
      <form
        id="search-form"
        className="login-form"
        onSubmit={methods.handleSubmit(handleNewSearch)}
      >
        <CabinAndPassengersPickers />
        <div className="searchFormGrid">
          <SearchLocationPicker />
          <SearchDatePicker />
          <SearchButton isLoading={isLoading} />
        </div>
      </form>
    </FormProvider>
  );
});

export default SearchForm;
export { SearchFormWrapper };
